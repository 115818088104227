<template>
  <div id="pdfHTML" ref="pdfHTML" style="padding:20px 20px;margin:0 auto;width:100%;max-width:1200px;text-align:center;">
    <div style="text-align:left;"><img class="logo-img-2" :src="require('../../assets/ushop3.png')" @click="homeClick"></div>
    <el-breadcrumb separator-class="el-icon-arrow-right" style="padding:0px 5px;">
      <el-breadcrumb-item to="/account">My Account</el-breadcrumb-item>
      <el-breadcrumb-item to="/orders">Order</el-breadcrumb-item>
      <el-breadcrumb-item>Receipt</el-breadcrumb-item>
      <el-breadcrumb-item>{{orderId}}</el-breadcrumb-item>
    </el-breadcrumb>
    <h1 style="text-decoration: underline;">RECEIPT</h1>
    <div style="display:-webkit-box">
      <div style="-webkit-box-flex:1;">&nbsp;</div>
      <table style="text-align:right;">
        <tr>
            <td class="bold-text">Order Number:</td>
            <td>{{orderId}}</td>
        </tr>
        <tr>
            <td class="bold-text">Order Date:</td>
            <td>{{orderDate}}</td>
        </tr>
      </table>
    </div>
    <div style="text-align:left;">
      <table>
        <tr>
          <td class="bold-text">Client Name:</td>
          <td>{{contactName}}</td>
        </tr>
        <tr>
          <td class="bold-text">Deliver Location:</td>
          <td>{{expectedLocationName}}</td>
        </tr>
        <tr>
          <td class="bold-text">Points earned:</td>
          <td>{{pointsEarned}}</td>
        </tr>
      </table>
    </div>
    <div style="text-align:left;margin-top:20px;font-weight:bold;">Receipt Summary</div>
    <table border="1" cellspacing="0" cellpadding="10" style="width:100%;">
      <tr style="background-color:#909399;">
        <td>No.</td>
        <td>Description</td>
        <td>Unit</td>
        <td>Unit Price SGD</td>
        <td>Quantity</td>
        <td>Amount SGD</td>
      </tr>
      <tr v-for="(orderItem, index) in goodList" :key="index">
        <td>{{index + 1}}</td>
        <td>{{orderItem.product_name}}</td>
        <td>{{orderItem.sku_name}}</td>
        <td>{{order.is_member_by == 0 ? (orderItem.sku_price/100).toFixed(2) : (orderItem.sku_member_price/100).toFixed(2)}}</td>
        <td>{{orderItem.amount}}</td>
        <td>{{order.is_member_by == 0 ? (orderItem.sub_total/100).toFixed(2) : (orderItem.sub_member_total/100).toFixed(2)}}</td>
      </tr>
      <tr>
        <td style="text-align:right;font-weight:bold;" colspan="5">Total Amont SGD</td>
        <td>{{orderTotal}}</td>
      </tr>
    </table>
    <div style="margin-top:50px;">
      This is computer generated document. No signature is required.
    </div>
    <div style="margin-top:20px;"><button @click="downloadPDF">Download PDF</button></div>
  </div>
</template>

<script>
import ModelReceipt from '../viewmodel/ModelReceipt.vue'
import jsPDF from 'jspdf'
export default {
  extends: ModelReceipt,
  name: 'ReceiptView',
  methods: {
      downloadPDF(){
        var doc = new jsPDF('l', 'mm', [1200, 1250])
        console.log(doc)
      
        var pdfHtml = window.document.getElementById('pdfHTML')
        console.log(pdfHtml)
        doc.html(pdfHtml, {
          callback: (doc) => doc.save(this.orderId + '.pdf')
        })
      
        //doc.save(this.orderId + '.pdf')
      }
  }
}
</script>

<style scoped>
.bold-text {
  font-weight: bold;
}
.logo-img-2 {
    height:80px;
    width: 198px;
    cursor: pointer;
}
</style>